<template>
  <header
    class="top-0 z-50 flex fixed w-full justify-between items-center space-x-1 md:py-3 ease-in-out duration-300 transition-all"
    :class="
      isTransparent && topOfPage && !openSearch && !hoverWorkMenu && !mobileMenu
        ? 'bg-transparent'
        : ' bg-white shadow-md'
    "
  >
    <div class="w-full mx-auto flex flex-wrap items-center justify-between">
      <HeaderNav
        :is-transparent="isTransparent"
        :top-of-page="topOfPage"
        @search-openned="toggleSearch"
        @hovering-work-menu="toggleWorkMenu"
        @open-mobile-menu="toggleMobileMenu"
      />
    </div>
  </header>
</template>

<script setup>
const route = useRoute()

const openSearch = ref(false)
const hoverWorkMenu = ref(false)
const mobileMenu = ref(false)

const isTransparent = computed(() => {
  return (
    route.path === '/' ||
    route.path === '/sustainability' ||
    route.path === '/about' ||
    route.path.includes('/industries') ||
    route.path.includes('/products') ||
    route.path.includes('/expertises') ||
    route.path.includes('/solutions') ||
    route.path === '/work' ||
    route.path === '/insights' ||
    route.path === '/careers' ||
    route.path === '/careers/culture' ||
    route.path === '/contact'
  )
})

const topOfPage = ref(true)

const updateNav = () => {
  if (window.scrollY > 75) {
    topOfPage.value = false
  } else if (!topOfPage.value) topOfPage.value = true
}

onBeforeMount(() => window.addEventListener('scroll', updateNav))

const toggleSearch = searchState => {
  openSearch.value = searchState
}
const toggleWorkMenu = isHovering => {
  hoverWorkMenu.value = isHovering
}
const toggleMobileMenu = isOpenning => {
  mobileMenu.value = isOpenning
}

watch(
  () => route.path,
  () => {
    mobileMenu.value = false
    hoverWorkMenu.value = false
  }
)
</script>

<style lang="scss" scoped>
.logos-invert {
  filter: brightness(0) invert(1);

  &:hover {
    filter: brightness(1) invert(0);
  }
}
</style>
