import { useMainMenuStore } from '~/store/mainMenu'

export default async () => {
  const mainMenu = useMainMenuStore()

  const { data, error } = await useSuitmediaApiData(
    `/api/menus/1?nested&populate=*`,
    {
      retry: 3,
      retryDelay: 300
    }
  )

  if (error.value) {
    throw createError({
      ...error.value,
      statusMessage: 'Unable to fetch menus'
    })
  }

  const allMenus = data.value.data.attributes.items.data

  allMenus.forEach(parentMenu => {
    if (parentMenu.attributes.children.data.length > 0) {
      parentMenu.attributes.children.data.forEach(async childrenMenu => {
        if (!childrenMenu.attributes.children.data.length) {
          const query = {}
          let newExpertises = []

          if (
            childrenMenu.attributes.api_path === '/industries' ||
            childrenMenu.attributes.api_path === '/products'
          ) {
            query['sort[0]'] = 'title:asc'
          }

          if (childrenMenu.attributes.api_path === '/solutions') {
            query['sort[0]'] = 'order'
          }

          if (childrenMenu.attributes.api_path === '/expertices') {
            query['populate[expertice_category][fields][0]'] = 'title'
            query['populate[expertice_category][fields][1]'] = 'slug'
            query['sort[0]'] = 'order'
          }

          const { data, error } = await useSuitmediaApiData(
            `/api${childrenMenu.attributes.api_path}`,
            { query, retry: 3, retryDelay: 300 }
          )
          if (error.value) console.error(error.value)

          if (childrenMenu.attributes.api_path === '/expertices') {
            newExpertises = mainMenu.expertises.map(expert => {
              return {
                id: expert.id,
                ...expert.attributes,
                grandchild: data.value.data.filter(
                  item =>
                    item.attributes.expertice_category?.data?.attributes
                      ?.title === expert.attributes.title
                )
              }
            })

            mainMenu.changeExpertises(newExpertises)
          }
          childrenMenu.attributes.children.data =
            childrenMenu.attributes.api_path === '/expertices'
              ? newExpertises
              : data.value.data
        }
      })
    }
  })

  mainMenu.changeMenus(allMenus)

  return data
}
